<!-- 页面程序 -->
<template>
    <div  class="home" :style="{ 'background': backGroundColor} ">
        <header class="topView">
            <img class="topImage" :src="activityTopImg" />
            <div class="top_box">
                <section class="box_item" v-for="(item, index) in activityHeardList" wx:key='index'>
                    <div class='main_one' @click="chThis(index)" >
                        <img class="main_one" :src='imgIndex == index ?item.hoverUrl:item.url' />
                    </div>
                </section>
            </div>
        </header>
        <div class="bottomView" id="divBox">
            <div class="porject_items" v-for="(item, index) in activityList" :key="index"  :id=" 'k-' +index">
                <section class="porject_item">
                <div class=" porject_item_top">
                    <img class="porject_bgImg" :src="topTitleImg"/>
                    <div class="porject_item_title">{{ item.firstTitle }}</div>
                </div>
                <div class="porject_bottom" v-if="activityId == 1 ">
                    <div class="porject_bottom_items">
                        <img class="porject_bottom_items_img" v-for="(inner, index) in item.aslrList" :src="inner.uri" :key="index"/>
                    </div>
                </div>
                <div v-else>
                  <div class="porject_bottom_items_wash">
                    <div class="porject_bottom_items_wash_items" v-for="(inner, index) in item.aslrList" :key="index" @click="getServiceData(inner)">
                        <img style="width: 100%;height: 120px;" :src="inner.photoPath"/>
                        <span class="porject_bottom_items_wash_items_name">{{ inner.projectName }}</span>
                        <span class="porject_bottom_items_wash_items_desc">{{ inner.description }}</span>
                        <div class="porject_bottom_items_wash_items_price">
                            <span class="font16" style="font-weight: 800; color: #E95606;">￥{{ 1 > inner.oltCount ?
                                    (inner.minimumNewcomerDiscount / 100).toFixed(2) :
                                    (inner.lowestDiscountPrice / 100).toFixed(2)
                            }}</span>
                            <span style="color: #999999;text-decoration: line-through;font-size: 12px;">￥{{
                                    (inner.theLowestPrice / 100).toFixed(2)
                            }}</span>
                        </div>
                    </div>
                  </div>  
                </div>
            </section>
            </div>

            <div class="porject_recommend">
                <section class="project_title_view" :style="{ 'color': titleColor }">
                    <span class="project_title_view_line" :style="{ 'background': titleColor }"></span>
                    <span style="margin-left: 20px;margin-right: 20px;">推荐服务</span>
                    <span class="project_title_view_line" :style="{ 'background': titleColor }"></span>
                </section>
                <section class="porject_recommend_bottom">
                    <div class="porject_recommend_scroll_item" v-for="(item, index) in recommendList" :key="index"
                        @click="getServiceData(item)">
                        <section style="display: flex;flex-direction: column;">
                            <img class="spikeByTime_img" style="border-radius:10px; height: 120px;" :src="item.photoPath" />
                            <span class="spikeByTime_name">{{ item.projectName }}</span>
                            <span class="spikeByTime_desc">{{ item.description }}</span>
                            <div class="spikeByTime_price">
                                <span class="font16" style="width: 50%;font-weight: 800; color: #EE4908;">￥{{ 1 > item.oltCount ?
                                        (item.minimumNewcomerDiscount / 100).toFixed(2) :
                                        (item.lowestDiscountPrice / 100).toFixed(2)
                                }}</span>
                                <span style="color: #666666;text-decoration: line-through;font-size: 12px;">￥{{
                                        (item.theLowestPrice / 100).toFixed(2)
                                }}</span>
                            </div>
                        </section>
                    </div>
                </section>
            </div>

            <div class="bottom_img">
                <img :src="activityBottomImg" style="width: 100% ; height: 100%; " />
            </div>
        </div>
    </div>
</template>

<script>

import wx from "weixin-js-sdk-ts";

export default {
    components: {},
    data() {
        return {
            staticUrl:"http://resources.sxdjcq.com/mini",
            activityId: this.$route.query.activityId,
            memberId: this.$route.query.memberId,
            activityTopImg: "",
            activityBottomImg: "",
            activityList: [],
            recommendList: [],
            imgIndex: 0,
            topTitleImg:"",
            backGroundColor: this.$route.query.activityId == 1 ? '#7DCEF9' : '#FFADA8',
            titleColor: this.$route.query.activityId == 1 ? '#2783B6' : '#333333',
            //类目点击按钮数组
            activityHeardList: [],
        };
    },
    methods: {
        getBaseInfo() {
            let params = {
                activityId: this.activityId,
                memberId: this.memberId,
                activityList: [],
            };
            this.$axios({
                url: this.$requests.member.getNewActiveDataList,
                method: "post",
                data: params,
            }).then((res) => {
                let wrapper = res.data || {};
                let list = wrapper.data || [];
                let top_data = list.shift()
                let array = list.reverse()
                let bottom_data = array.shift()
                let activityList = list.reverse()

                this.activityTopImg = top_data.aslrList[0].uri,
                    this.activityBottomImg = bottom_data.aslrList[0].uri,
                    this.activityList = activityList
            }).catch((e) => { });
        },
        getReferralServiceList() {
            let params = {
                placeAnOrder: 0,
                memberId: this.memberId,
            };
            this.$axios({
                url: this.$requests.member.getNewActiveSalesList,
                method: "post",
                data: params,
            }).then((res) => {
                let wrapper = res.data || {};
                this.recommendList = wrapper.data || [];
            }).catch((e) => { });
        },
        getServiceData(e) {
            var ua = navigator.userAgent.toLowerCase()
            if (ua.match(/MicroMessenger/i) === 'micromessenger' || ua.indexOf('micromessenger') !== -1) {
            //第一种情况在微信小程序中没有奏效，第二种奏效了
            //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
                wx.miniProgram.getEnv((res) => {
                    if (res.miniprogram) {
                        console.log('在小程序')
                        let path = "/pages/subPackages/orderSubPackages/servicedetails/servicedetails?projectId=" + e.projectId     
                        wx?.miniProgram.navigateTo({
                            url: path
                        })
                    } else {
                        console.log('不在小程序')
                        let href = "next://getServiceData" + ',' + e.projectId
                        window.location.href = href;
                    }
                })
            } else {
                let href = "next://getServiceData" + ',' + e.projectId
                window.location.href = href;
                return
            }
        },
        chThis(e){
            this.imgIndex = e;
            let target =  'k-' + e;
            document.getElementById(target).scrollIntoView();
        },
        loadHeardList(){
            let bg_img;
            let heardList;
            if (this.activityId != 1) {
                bg_img = this.staticUrl + '/wash/icon_wash_title_bg.png';
                heardList = [{
                    url: this.staticUrl + '/wash/icon_wash_heard_1.png',
                    hoverUrl: this.staticUrl + '/wash/icon_wash_heard_1.png',
                }, {
                    url: this.staticUrl + '/wash/icon_wash_heard_2.png',
                    hoverUrl: this.staticUrl + '/wash/icon_wash_heard_2.png',
                }, {
                    url: this.staticUrl + '/wash/icon_wash_heard_3.png',
                    hoverUrl: this.staticUrl + '/wash/icon_wash_heard_3.png',
                }, {
                    url: this.staticUrl + '/wash/icon_wash_heard_4.png',
                    hoverUrl: this.staticUrl + '/wash/icon_wash_heard_4.png',
                }];
            } else {
                bg_img = this.staticUrl + '/wash/icon_quality_title_bg.png';
                heardList = [{
                    url: this.staticUrl + '/wash/icon_quality_heard_1.png',
                    hoverUrl: this.staticUrl + '/wash/icon_quality_heard_1_1.png',
                }, {
                    url: this.staticUrl + '/wash/icon_quality_heard_2.png',
                    hoverUrl: this.staticUrl + '/wash/icon_quality_heard_2_1.png',
                }, {
                    url: this.staticUrl + '/wash/icon_quality_heard_3.png',
                    hoverUrl: this.staticUrl + '/wash/icon_quality_heard_3_1.png',
                }, {
                    url: this.staticUrl + '/wash/icon_quality_heard_4.png',
                    hoverUrl: this.staticUrl + '/wash/icon_quality_heard_4_1.png',
                }];
            }
            this.topTitleImg = bg_img;
            this.activityHeardList = heardList;
        },
    },
    mounted() {
        // 获取活动详情信息
        this.getBaseInfo();
        // 获取推荐数据
        this.getReferralServiceList();
        // 获取顶部图片
        this.loadHeardList();
    },
};
</script>

<style lang="less" scoped>
.home{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.topView{
    width: 100%;
}

.bottomView{
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    overflow: scroll;
}

.porject_bottom_items{
    margin-left: 2%;
    width: 96%;
    height: 100%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    padding-bottom: 10px;
}

.porject_bottom_items_wash{
    margin-left: 2%;
    width: 96%;
    height: 100%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-bottom: 10px;
}
.porject_bottom_items_wash_items{
    margin-top: 15px;
    margin-left: 10px;
    width: 45%;
    display: flex;
    flex-direction: column;
    background: #FFD2CF;
    box-shadow: 0px 1px 4px 0px #93221B;
    border-radius: 8px;
    overflow: hidden;
}

.porject_bottom_items_img{
    margin-top: 10px;
    margin-left: 10px;
    width: 45%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
}
.topImage {
    width: 100%;
}

.top_box {
    width: 100%;
    height: 24vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.box_item {
    width: 20vw;
    height: 20vw;
}

.main_one {
    width: 20vw;
    height: 20vw;
}

.porject_items {
    margin-top: 10px;
    width: 100%;
}
.porject_item {
    width: 100%;
    padding-bottom: 1px;
}

.porject_item_top {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.porject_bgImg {
    height: 30px;
}
.porject_item_title {
    position: absolute;
    margin: auto;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: 0px 1px 2px rgba(117,28,22,0.6000);
}

.porject_bottom{
  height: 160px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.porject_recommend_bottom {
    height: 205px;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    overflow-x: scroll;
    white-space: nowrap;
    overflow-y: hidden;
}

.porject_recommend_scroll_item {
    margin-top: 5px;
    margin-right: 5px;
    height: 180px;
    width: 35vw;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 1px 9px 1px rgba(179, 110, 69, 0.4600);
    border-radius: 5px;
    overflow: hidden;
    display: inline-table;
}

.porject_bottom_items_wash_items_name{
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 3px;
    font-size: 14px;
    color: #333333;
    font-weight: 500;
    white-space: nowrap;
}

.porject_bottom_items_wash_items_desc{
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 11px;
    color: #666666;
    font-weight: 500;
    white-space: nowrap;
}
.porject_bottom_items_wash_items_price{
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 20px;
    align-items: baseline;
}

.project_title_view {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}

.project_title_view_line {
    width: 50px;
    height: 1px;
}

.spikeByTime_img {
    width: 100%;
    height: 55%;
}

.spikeByTime_name {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 33vw;
    margin-top: 2px;
    margin-left: 1vw;
    font-size: 14px;
    color: #333333;
    font-weight: 500;
}

.spikeByTime_desc {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 33vw;
    margin-top: 5px;
    margin-left: 1vw;
    font-size: 11px;
    color: #666666;
    font-weight: 500;
}

.spikeByTime_price {
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 20px;
    align-items: baseline;
}
</style>